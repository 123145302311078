import { maska } from 'maska'
import VOtpInput from './plugins/vue3-otp-input/vue3-otp-input'



export function install(Vue) {
  Vue.directive('maska', maska)
  Vue.component('VOtpInput', VOtpInput)
}

export default { install }
